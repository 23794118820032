@value mediumUp, largeUp from "../../../ui/base/metrics/metrics.css";

.imageWrapper {
    margin: auto;
}

.imageWrapper .image {
    width: 100%;
}

.section {
    width: 100%;
    padding: 0 16px 8px;
}

.embed {
    width: 100%;
    height: 225px;
}

.embed > * {
    width: 100%;
    height: 100%;
}

@media mediumUp {
    .embed {
        height: 450px;
    }
}
@media largeUp {
    .embed {
        height: 675px;
    }
}

.cta {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 8px;
    padding: 0 16px;
}

@media mediumUp {
    .cta {
        flex-direction: row;
        justify-content: space-between;
    }
    .cta .button {
        width: 100%;
    }
}

.actionCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 8px;
    grid-auto-rows: 1fr;
    max-width: 90%;
}

.backButton {
    text-decoration: none !important;
    box-shadow: none !important;
    border: none !important;
    background: none !important;
}

.backButton:hover {
    background: none;
}

.arrow {
    margin-right: 8px;
}
