@value defaultDown, gridBaseline from "../metrics/metrics.css";

/* Weights should be kept in sync with the Open-Sans import at /ui/base/fonts/local_open_sans.css. */
@value fontWeightRegular: 400;
@value fontWeightSemiBold: 600;
@value fontWeightBold: 700;
@value fontSizeTitleExtraExtraLarge: 6.4rem;
@value mobileFontSizeTitleExtraExtraLarge: 4.8rem;
@value fontSizeTitleExtraLarge: 4.8rem;
@value mobileFontSizeTitleExtraLarge: 3.2rem;
@value fontSizeTitleLarge: 3.2rem;
@value mobileFontSizeTitleLarge: 2.4rem;
@value fontSizeTitleMedium: 2.4rem;
@value mobileFontSizeTitleMedium: 2.0rem;
@value fontSizeTitleSmall: 1.8rem;
@value mobileFontSizeTitleSmall: 1.6rem;
@value fontSizeTitleExtraSmall: 1.4rem;
@value fontSizeTitleExtraExtraSmall: 1.2rem;
@value fontSizeExtraExtraLarge: 2.4rem;
@value mobileFontSizeExtraExtraLarge: 2.1rem;
@value fontSizeExtraLarge: 2.1rem;
@value mobileFontSizeExtraLarge: 1.8rem;
@value fontSizeLarge: 1.6rem;
@value fontSizeMedium: 1.4rem;
@value fontSizeSmall: 1.2rem;
@value fontSizeExtraSmall: 1.0rem;
@value textTitleExtraExtraLargeLineHeight: 8.4rem;
@value mobileTextTitleExtraExtraLargeLineHeight: 6.4rem;
@value textTitleExtraLargeLineHeight: 6.4rem;
@value mobileTextTitleExtraLargeLineHeight: 4.2rem;
@value textTitleLargeLineHeight: 4.2rem;
@value mobileTextTitleLargeLineHeight: 3.2rem;
@value textTitleMediumLineHeight: 3.2rem;
@value mobileTextTitleMediumLineHeight: 2.6rem;
@value textTitleSmallLineHeight: 2.4rem;
@value mobileTextTitleSmallLineHeight: 2.4rem;
@value textTitleExtraSmallLineHeight: 2rem;
@value textTitleExtraExtraSmallLineHeight: 1.8rem;
@value textExtraExtraLargeLineHeight: 3.6rem;
@value mobileTextExtraExtraLargeLineHeight: 3.2rem;
@value textExtraLargeLineHeight: 3.2rem;
@value mobileTextExtraLargeLineHeight: 2.8rem;
@value textLargeLineHeight: 2.6rem;
@value textMediumLineHeight: 2.2rem;
@value textSmallLineHeight: 2rem;
@value textExtraSmallLineHeight: 1.6rem;

@value systemFontStack: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
@value defaultFontStack: "FSAlbert", "Noto Sans Variable", "Noto Sans", systemFontStack;

.fontSmoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
}

.base {
    margin: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.lineClamp {
    display: -webkit-box;
    overflow: hidden;
}

/* Seperate block with no autoprefixer to preserve -webkit-box-orient */
.lineClamp {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
}

.ellipsis {
    max-width: 100%;
    white-space: nowrap;
    /* stylelint-disable-next-line canva/discouraged-truncated-text */
    text-overflow: ellipsis;
    overflow: hidden;
    /* so setting an inline tag on text with overflow works properly */
    display: block;
}

.allowUserSelect {
    user-select: text;
}

.uppercase {
    text-transform: uppercase;
}

/* Sizes */

.textTitleExtraExtraLarge,
.textTitleExtraLarge,
.textTitleLarge,
.textTitleMedium,
.textTitleSmall,
.textTitleExtraSmall,
.textTitleExtraExtraSmall {
    /** same as .base */
    margin: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    /** same as .fontSmoothing */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;

    font-family: defaultFontStack;
    font-weight: fontWeightBold;
}

.textTitleExtraExtraLarge {
    font-size: fontSizeTitleExtraExtraLarge;
    line-height: textTitleExtraExtraLargeLineHeight;
}

.textTitleExtraLarge {
    font-size: fontSizeTitleExtraLarge;
    line-height: textTitleExtraLargeLineHeight;
}

.textTitleLarge {
    font-size: fontSizeTitleLarge;
    line-height: textTitleLargeLineHeight;
}

.textTitleMedium {
    font-size: fontSizeTitleMedium;
    line-height: textTitleMediumLineHeight;
}

.textTitleSmall {
    font-size: fontSizeTitleSmall;
    line-height: textTitleSmallLineHeight;
}

.textTitleExtraSmall {
    font-weight: fontWeightSemiBold;
    font-size: fontSizeTitleExtraSmall;
    line-height: textTitleExtraSmallLineHeight;
}

.textTitleExtraExtraSmall {
    font-weight: fontWeightSemiBold;
    font-size: fontSizeTitleExtraExtraSmall;
    line-height: textTitleExtraExtraSmallLineHeight;
}

.textExtraExtraLarge,
.textExtraLarge,
.textLarge,
.textMedium,
.textSmall,
.textExtraSmall,
.textBoldLarge,
.textBoldMedium,
.textBoldSmall,
.textBoldExtraSmall {
    /** same as .base */
    margin: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    /** same as .fontSmoothing */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;

    font-family: defaultFontStack;
    font-weight: fontWeightRegular;
    /** sets Canva Sans (normal body font) */
    font-variation-settings: "opsz" 0;
}

.textExtraExtraLarge {
    font-size: fontSizeExtraExtraLarge;
    line-height: textExtraExtraLargeLineHeight;
}

.textExtraLarge {
    font-size: fontSizeExtraLarge;
    line-height: textExtraLargeLineHeight;
}

.textLarge,
.textBoldLarge {
    font-size: fontSizeLarge;
    line-height: textLargeLineHeight;
}

.textMedium,
.textBoldMedium {
    font-size: fontSizeMedium;
    line-height: textMediumLineHeight;
}

.textSmall,
.textBoldSmall {
    font-size: fontSizeSmall;
    line-height: textSmallLineHeight;
}

.textExtraSmall,
.textBoldExtraSmall {
    font-weight: fontWeightSemiBold;
    font-size: fontSizeExtraSmall;
    line-height: textExtraSmallLineHeight;
}

/* stylelint-disable-next-line  selector-max-type */
.textLarge b,
.textLarge strong,
.textBoldLarge {
    font-weight: fontWeightSemiBold;
}

/* stylelint-disable-next-line  selector-max-type */
.textMedium b,
.textMedium strong,
.textBoldMedium {
    font-weight: fontWeightSemiBold;
}

/* stylelint-disable-next-line  selector-max-type */
.textBoldMedium b,
.textBoldMedium strong {
    font-weight: fontWeightBold;
}

/* stylelint-disable-next-line  selector-max-type */
.textSmall b,
.textSmall strong,
.textBoldSmall {
    font-weight: fontWeightSemiBold;
}

/* stylelint-disable-next-line  selector-max-type */
.textExtraSmall b,
.textExtraSmall strong,
.textBoldExtraSmall {
    font-weight: fontWeightSemiBold;
}

.textBoldExtraExtraLarge,
.textBoldExtraLarge,
.textExtraExtraSmall,
.textBoldExtraExtraSmall {
    /** Combinations not available, classnames are preserved for the CSS Modules typings */
}

/* Mobile font sizes */
@media defaultDown {
    .textTitleExtraExtraLarge {
        font-size: mobileFontSizeTitleExtraExtraLarge;
        line-height: mobileTextTitleExtraExtraLargeLineHeight;
    }

    .textTitleExtraLarge {
        font-size: mobileFontSizeTitleExtraLarge;
        line-height: mobileTextTitleExtraLargeLineHeight;
    }

    .textTitleLarge {
        font-size: mobileFontSizeTitleLarge;
        line-height: mobileTextTitleLargeLineHeight;
    }

    .textTitleMedium {
        font-size: mobileFontSizeTitleMedium;
        line-height: mobileTextTitleMediumLineHeight;
    }

    .textTitleSmall {
        font-size: mobileFontSizeTitleSmall;
        line-height: mobileTextTitleSmallLineHeight;
    }

    .textExtraExtraLarge {
        font-size: mobileFontSizeExtraExtraLarge;
        line-height: mobileTextExtraExtraLargeLineHeight;
    }

    .textExtraLarge {
        font-size: mobileFontSizeExtraLarge;
        line-height: mobileTextExtraLargeLineHeight;
    }
}

/* Margins */

.margin:not(:last-child) {
    margin-bottom: gridBaseline;
}

/* Alignments */

.inherit {
    text-align: inherit;
}

.start {
    text-align: start;
}

.center {
    text-align: center;
}

.end {
    text-align: end;
}

