.indicator {
    width: 6px;
    height: 6px;
    margin: 3px;
    background-color: #bbbbbb;
    border-radius: 50%;
    display: inline-block;
}

.indicator.active {
    background-color: #ffae49;
}

.indicator.hidden {
    display: none;
}

.indicator.small {
    transform: scale(0.75);
}
