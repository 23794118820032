.indicators {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 25%;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
}
