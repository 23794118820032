@value smallUp, mediumUp, largeUp from "../../ui/base/metrics/metrics.css";

@value bgDark #005a29;

.pageWrapper {
  padding: 16px env(safe-area-inset-right) 0 env(safe-area-inset-left);
  margin-top: calc(24px + env(safe-area-inset-top));
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.divider {
  width: 88px;
  height: 3px;
  margin-top: 18px;
  margin-bottom: 21px;
  border-radius: 2px;
  background-color: #009645;
}

.wrapper {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding-left: 12px;
  padding-right: 12px;
}

.button {
  padding: 12px 24px;
  border-radius: 6px;
  height: 48px;
  line-height: 24px;
  font-size: 17px;
  font-weight: 500;
  background-color: #009645 !important;
  color: white !important;
  text-decoration: none;
}

.button:hover {
  background-color: #43a854 !important;
  color: white !important;
}

/** HERO **/

.heroIntro {
  width: 100%;
}

.heroButton {
  width: 100%;
}

.heroIntroText {
  color: white;
  margin-bottom: 16px;
}

.heroIntroTitle {
  margin-top: 32px;
  font-size: 36px;
  line-height: 48px;
}

.heroIntroSub {
  line-height: 28px;
  margin-bottom: 30px;
}

.flexRow {
  width: 90%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.hero {
  color: white;
  background-color: bgDark;
  padding: 32px 32px 106px;
  position: relative;
  overflow: hidden;
}

.curve {
  position: absolute;
  bottom: 0;
  width: 150%;
  left: -25%;
}

.heroImage {
  width: 100%;
  max-width: 400px;
}

.heroBgElements {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  height: 100%;
  min-height: 640px;
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  opacity: 0.05;
}

.heroBgElement1 {
  position: absolute;
  margin: auto;
  top: auto;
  right: auto;
  left: -27.9vw;
  bottom: 8vh;
  width: 48vw;
  height: 48vw;
  border-radius: 8vw;
  background-image: linear-gradient(118deg, #fff, #fff, hsla(0, 0%, 100%, 0));
  transform: rotate(45deg);
}

.heroBgElement2 {
  position: absolute;
  top: 14.2vh;
  right: -16vw;
  width: 28vw;
  height: 28vw;
  border-radius: 6vw;
  left: auto;
  bottom: auto;
  background-image: linear-gradient(163deg, #fff, #fff, hsla(0, 0%, 100%, 0));
  transform: rotate(30deg);
}

.heroBgElement3 {
  position: absolute;
  left: 24%;
  width: 30vw;
  height: 30vw;
  top: 8vh;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #fff, #fff, hsla(0, 0%, 100%, 0));
  transform: rotate(30deg);
}

.heroBgElement4 {
  position: absolute;
  width: 24vw;
  height: 24vw;
  border-radius: 6vw;
  left: auto;
  top: auto;
  right: 22.4vh;
  bottom: 7.7vh;
  background-image: linear-gradient(159deg, #fff, #fff, hsla(0, 0%, 100%, 0));
  transform: rotate(60deg);
}

@media smallUp {
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .heroButton {
    width: auto;
  }
}

@media mediumUp {
  .section {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }

  .curve {
    width: calc(100% + 200px);
    left: -100px;
  }

  .hero {
    padding-top: 106px;
    padding-bottom: 140px;
    text-align: justify;
  }
}

@media largeUp {
  .heroIntroTitle {
    font-size: 48px;
    line-height: 56px;
  }

  .heroIntro {
    width: 45%;
    max-width: 480px;
    margin-right: 40px;
  }

  .heroImage {
    width: auto;
    margin-left: 16px;
    margin-top: 8px;
  }

  .curve {
    width: 100%;
    left: 0;
  }

  .flexRow {
    flex-direction: row;
  }

  .hero {
    text-align: left;
  }

  .heroBgElement1 {
    left: -30.7vh;
    bottom: -19vh;
    width: 60vh;
    height: 60vh;
    border-radius: 10vh;
  }

  .heroBgElement2 {
    top: 0vh;
    right: -14.9vh;
    width: 22vh;
    height: 22vh;
    border-radius: 5vh;
  }

  .heroBgElement3 {
    left: 40%;
    width: 22vh;
    height: 22vh;
  }

  .heroBgElement4 {
    width: 16vh;
    height: 16vh;
    border-radius: 4vh;
  }
}

/** CARDS LIST **/

.descriptionText {
  max-width: 580px;
  margin: auto auto 32px;
  color: #666 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.cardsList {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: 1fr;
  margin-top: 32px;
}

.card {
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #e3e3e3;
  border-radius: 20px;
  background-color: #fff;
  transition: transform 400ms ease, border-color 400ms ease, box-shadow 400ms ease;
  color: #151515;
  text-align: center;
  text-decoration: none;
}

.card:hover {
  border-color: transparent;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transform: translate(0px, -6px);
}

.cardImage {
  width: 80%;
  margin-bottom: 16px;
  flex: 1;
  object-fit: contain;
}

.cardsListBottom {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

@media smallUp {
  .cardsList {
    grid-template-columns: 1fr 1fr;
  }
}

@media mediumUp {
  .cardsList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/** PARTNER ZOOS **/

.logosList {
  display: flex;
  justify-content: center;
}

.logoLink {
  margin: 20px 8px;
  padding: 8px;
  transition: opacity 400ms ease, transform 400ms ease;
}

.logo {
  height: 150px;
  width: auto;
}

.haze {
  background-color: #eef3f0;
}

/** FAQ **/

.faq {
  position: relative;
  display: flex;
  flex-direction: column;
}

.faqIntro {
  margin-bottom: 40px;
  position: static;
}

.faqItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  font-size: 14px;
}

.faqItemInfo {
  flex: 9;
}

.infoIcon {
  flex: 1;
  height: 40px;
  margin-top: 16px;
  margin-right: 16px;
}

@media mediumUp {
  .faq {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 64px;
  }

  .faqIntro {
    position: sticky;
    grid-column-start: span 4;
    grid-column-end: span 4;
    grid-row-start: 1;
    grid-row-end: 2;
    top: 160px;
  }

  .faqBody {
    grid-column-start: 6;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 6;
  }
}

/** START JOURNEY **/

.startJourney {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 660px;
  text-align: center;
  font-size: 14px;
}

.startJourneyImage {
  width: 100%;
  max-width: 500px;
}
