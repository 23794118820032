.icon {
  width: 16px;
  height: 16px;
}

.clickable {
  cursor: pointer;
}

.left {
  transform: rotate(180deg);
}
