.picker {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-weight: bold;
}

.brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 400px;
}

.brand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    margin: 10px;
    cursor: pointer;
}

.back {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}